

<script>

import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    value: Number, // Vue 2: value
    options: {
        type :Object,
        default: () => ({currency: 'CAD', currencyDisplay: 'narrowSymbol'})
    }
  },
  setup(props) {
    const {inputRef,setValue} = useCurrencyInput(props.options, true)
   
    return { inputRef, setValue }
  },

  
}
</script>



<template>
    <input ref="inputRef" class="form-control"  type="text" />
</template>